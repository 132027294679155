<template>
  <!-- <div
      v-if="survey"
      class="survey__modal"
      style="display: inline-block;"
    >
      <button class="tsmd__dim" />
      <div class="pb-12 tsmd__inner">
        <button
          class="tsmd__close__btn el-dialog__close el-icon el-icon-close"
          @click="survey=false;"
        />
        <div
          class="relative pt-8 text-left tsmd__txt px-14"
          style="width: 90%; height: 208px; margin: 0 auto !important;"
        >
          <div
            class="relative inline-block pl-2 align-middle pt-7"
            style="width: 55%; top: 20px"
          >
            <span class="relative inline-block text-3xl font-bold">
              <span class="relative z-10">쿡앱스 홈페이지</span>
              <span
                class="absolute bar__animation"
                :class="{ bar__animation: survey }"
              />
            </span>
            <br>
            <span class="relative inline-block text-2xl font-normal">
              <span class="relative z-10 font-bold">얼마나 만족하셨나요?</span>
              <span
                class="absolute"
                :class="{ bar__animation: survey, bar__animation__2: survey }"
              />
            </span>
          </div>
          <img
            class="absolute survey__img"
            style="width: 216px; right: 30px; top: -3px;"
            src="@/assets/recruit/cookie_complete.png"
            alt="쿡이"
          >
        </div>
        <div
          class="mx-16 tsmd__subTxt"
          style="border-top: 1px solid #CCC;"
        >
          <p class="mt-1 text-sm text-right">
            설문조사의 응답 결과는 <span class="font-bold font-primary-color">모두 익명</span>으로 처리됩니다.
          </p>
          <ul class="mt-5 text-left">
            <li>
              <span class="block text-lg font-bold">
                지원 과정의
                <span class="highlight required">
                  <span>만족도</span>
                </span>
                <i class="highlight__star required">*</i>
              </span>
              <p class="mt-2 text-base">
                쿡앱스 웹사이트에서의 지원하는 과정이 전체적으로 만족스러우셨나요?
              </p>
              <div class="mt-4 text-center survey__answer">
                <el-rate v-model="surveyResult.satisfaction" />
              </div>
            </li>
            <li class="mt-8">
              <span class="block text-lg font-bold">
                지원 과정의
                <span class="highlight required">
                  <span>편리함</span>
                </span>
                <i class="highlight__star required">*</i>
              </span>
              <p class="mt-2 text-base">
                쿡앱스 웹사이트에서의 지원이 다른 플랫폼보다 쉽고 간편했나요?
              </p>
              <div class="mt-4 text-center survey__answer">
                <el-rate v-model="surveyResult.easy" />
              </div>
            </li>
            <li>
              <div class="mt-10 text-lg font-bold survey__ask">
                쿡앱스 웹사이트나 지원과정에 대한 개선사항 및 의견을 마음껏 작성해주세요!
              </div>
              <div class="mt-5 survey__answer">
                <el-input
                  v-model="surveyResult.opinion"
                  type="textarea"
                  :rows="3"
                  placeholder="지원자님의 의견을 작성해주세요."
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="tsmd__btn__box">
          <button
            class="tsmd__cancel__btn"
            @click="survey=false;"
          >
            닫기
          </button>
          <button
            class="tsmd__save__btn"
            @click="submitSurvey();"
          >
            설문완료
          </button>
        </div>
      </div>
    </div> -->

  <!-- 접수 완료 페이지 -->
  <!-- <div
      class="complete__page"
    >
      <div
        class="complete__inner"
        style="padding: 280px 2rem;"
      >
        <div style="max-width: 1200px; margin: 0 auto;">
          <div class="md:inline-block md:w-2/5 md:text-right">
            <img
              class="relative inline-block w-100 h-100"
              style="max-width: 200px;"
              src="@/assets/recruit/cookie_complete__2.png"
              alt="쿡이"
            >
          </div>
          <div class="text-center align-middle md:inline-block md:w-3/5">
            <div class="text-left md:ml-20">
              <div class="text-2xl font-bold text-black mt-9">
                <span class="highlight required"><span class="font-bold">지원이 완료되었습니다.</span></span>
              </div>
              <div
                class="mt-6 text-lg"
                style="line-height: 32px;"
              >
                입력하신 지원서를 토대로 <span class="font-bold">쿡앱스</span>에 지원이 완료되었습니다.<br>
                <span class="font-bold">입력해주신 이메일로</span> 지원결과를 받아보실 수 있으십니다.
              </div>
              <button
                class="mt-10 customBtn fontKR lg"
                @click="$router.push('/')"
              >
                <span>메인으로</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  
  <div
    v-if="$screen.breakpoint === 'pc'"
    class="w-full h-[985px]"
  >
    <div
      class="flex items-center justify-center h-full "
    >
      <div class="flex justify-between h-[459px] w-[790px]">
        <div>
          <div class="text-[56px] leading-normal tracking-normal font-bold h-[67px]">
            지원이 완료되었습니다.
          </div>
          <div class="text-[26px] leading-[1.27] tracking-normal text-gray-500 mt-[16px] h-[32px]">
            {{ $route.params.jobTitle }}
          </div>
          <div class="h-[1px] bg-gray-200 w-[411px] mt-[28px]" />
          <div 
            class="text-[20px] leading-[1.7] tracking-[-0.2px] text-gray-700  mt-[20px] h-[136px]"
            :style="'word-wrap: break-word; word-break: keep-all;'"
          >
            <span class="font-bold text-orange-500">
              {{ $route.params.name }}</span>님 쿡앱스 <span class="font-bold text-black">{{ $route.params.jobTitle }}</span> 포지션에<br>
            지원해 주셔서 진심으로 감사드립니다.<br>
            빠른 시일 내에 전형 결과를<br>
            안내드리도록 하겠습니다.
          </div>
          <CustomButton
            class="
              mt-[40px]
            "
            text="쿡앱스 스토리 둘러보기"
            size="2xl"
            rounded
            color="secondary"
            @click.native="$router.push('/story')"
          />
        </div>
        <div class="flex items-end">
          <img
            class="w-[247px] h-[410px] inline-block relative "
            style="max-width: 247px;"
            src="@/assets/recruit/cookie_complete__2.png"
            alt="쿡이"
          >
        </div>
      </div>
    </div>
  </div>
  <div 
    v-else
    class="tablet:px-8 mobile:px-4"
  >
    <div class="flex flex-col items-center justify-center">
      <div class="text-[30px] leading-[1.27] tracking-[-0.3px] font-bold h-[38px] mt-[72px]">
        지원이 완료되었습니다.
      </div>
      <div class="text-[18px] leading-[1.33] tracking-[-0.1] text-gray-500 h-[24px] mt-[8px]">
        {{ $route.params.jobTitle }}
      </div>
      <div class="h-[1px] bg-gray-200 w-[263px] mt-[24px]" />
      <div 
        class="text-[16px] leading-[1.5] tracking-[-0.1px] text-gray-700  mt-[24px] h-[96px] text-center"
        :style="'word-wrap: break-word; word-break: keep-all;'"
      >
        <span class="font-bold text-orange-500">
          {{ $route.params.name }}</span>님 쿡앱스 <span class="font-bold text-black">{{ $route.params.jobTitle }}</span> 포지션에<br>
        지원해 주셔서 진심으로 감사드립니다.<br>
        빠른 시일 내에 전형 결과를<br>
        안내드리도록 하겠습니다.
      </div>
      <CustomButton
        class=" mt-[32px] mb-[88px]"
        text="쿡앱스 스토리 둘러보기"
        size="lg"
        rounded
        color="secondary"
        @click.native="$router.push('/story')"
      />
    </div>
  </div>
</template>
<script>
import { putSurveyResult } from '@/api/applicant';
import '@/plugins/element.js';
import surveyModal from '../../components/modal/surveyModal.vue';
// import hompageSatisfiedModal from '@/components/modal/hompageSatisfiedModal';
import CustomButton from '@/components/Button/customButton.vue';
export default {
  name: 'ApplyNew',
  components:{
    CustomButton
  },
  data() {
    return {
      survey: true,
      surveyResult: {
        satisfaction: 0,
        easy: 0,
        opinion: '',
      },
    };
  },
  computed: {
    timeStamp(){
      return new Date().getTime();
    },
    loadingGif() {
      return require('@/assets/recruit/recruit_loading.gif');
    },
  },
  created(){
    if(sessionStorage.getItem('survey') === 'true'){
      this.$modal.open(surveyModal);
    }
    // setTimeout(() => {
    //   this.$modal.open(hompageSatisfiedModal);
    // }, 1500);
  },
  methods: {
    async submitSurvey() {
      this.survey=false;
      await putSurveyResult(this.surveyResult);
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/apply.scss';
</style>
<style lang="scss">
.complete__page{
  .complete_right-arrow{
    display: inline-block;
    width: 0px; height: auto;
    position: relative;
    bottom: 2px;
    transition: width 0.3s;
  }
  .tsmd__save__btn:hover .complete_right-arrow{
    width: 19px !important;
    margin-left: 10px;
  }
}
.el-rate__icon{
  font-size: 24px;
}
.bar__animation{
  width: 0%; height: 60%;
  top: 15px; left: 0;
  background: rgba($color: #F3791F, $alpha: .5);
  z-index: 0;
  animation: barAnimation 0.4s ease-in-out 1 forwards;
  &__2{
    height: 60%;
    background: rgba($color: #ffdf2c, $alpha: .5);
    transition-delay: 0.5s;
    animation-delay: 0.4s;
  }
}
@keyframes barAnimation {
  0%   { width: 0%; }
  100% { width: 100%; }
}
</style>
